body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background-color: #f8f9fa;
}

.jumbotron {
  background-color: #ff9800;
  color: white;
}

.btn-primary {
  background-color: #1976d2;
  border-color: #1976d2;
}

.btn-primary:hover {
  background-color: #004ba0;
  border-color: #004ba0;
}

body {
  background-color: #fff9e6;
}

.jumbotron {
  background-color: #ffc107;
  color: black;
}

.navbar {
  background-color: #ffeb3b;
}

.btn-primary {
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-primary:hover {
  background-color: #ffb300;
  border-color: #ffb300;
}

svg {
  width: 1rem;
  margin: 3px;
}

/* Add these styles to your CSS file */
/* Skeleton Loader */
.skeleton-loader {
  background-color: #e0e0e0; /* Light gray */
  border-radius: 4px;
  width: 100%; /* Adjust based on the content */
  height: 400px; /* Adjust height according to the image or content size */
  overflow: hidden;
  position: relative;
}

/* Shimmer animation */
.skeleton-loader::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.calendar-container {
  /* position: absolute; Position it absolutely */
  z-index: 9999; /* Set a high z-index */
}


.about-services{
  display: flex;
    flex-wrap: wrap;
    align-content: space-around;
    align-items: baseline;
    flex-direction: column;
}